// a good place for shared storage logic to live

export const REFERRAL_CODE_STORAGE_KEY = 'referrer';

/**
 * Stores the provided referrer in sessions storage for recall
 * by other spas
 * @param referrer the referrer
 */
export const storeReferrer = (referrer: string) =>
    sessionStorage.setItem(REFERRAL_CODE_STORAGE_KEY, referrer);

/**
 * Retrieves the referrer stored in session storage, if there is one
 */
export const getStoredReferrer = (): string | null =>
    sessionStorage.getItem(REFERRAL_CODE_STORAGE_KEY);
