import { registerApplication, start } from 'single-spa';
import { Client } from 'styletron-engine-atomic';
import { QueryClient } from 'react-query';
import {
    constructApplications,
    constructRoutes,
    constructLayoutEngine,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import { SpaProps } from './Types/SpaProps';

export * from './index';

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
    routes,
    loadApp({ name }) {
        // eslint-disable-next-line no-restricted-properties
        return System.import(name);
    },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

const styletronEngine = new Client();
const queryClient = new QueryClient();

const spaProps: SpaProps = {
    styletronEngine,
    queryClient,
};

applications.forEach((config) => {
    registerApplication<SpaProps>({
        ...config,
        customProps: (name, location) => ({
            ...((typeof config.customProps === 'function'
                ? config.customProps(name, location)
                : // eslint-disable-next-line @typescript-eslint/ban-types
                  config.customProps) as {}),
            ...spaProps,
        }),
    });
});

layoutEngine.activate();
start();
