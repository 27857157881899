import { DiveConfigurationType } from './Types';

const _DiveConfiguration: Record<
    keyof DiveConfigurationType,
    string | undefined
> = {
    twitterVerifierId: process.env.TWITTER_VERIFIER_ID,
    discordVerifierId: process.env.DISCORD_VERIFIER_ID,
    googleVerifierId: process.env.GOOGLE_VERIFIER_ID,
    googleClientId: process.env.GOOGLE_CLIENT_ID,
    web3AuthClientId: process.env.WEB3AUTH_CLIENT_ID,
    web3AuthNetwork: process.env.WEB3AUTH_NETWORK,
    polygonJsonRpc: process.env.POLYGON_JSON_RPC,
    polygonChainId: process.env.POLYGON_CHAIN_ID,
    polygonBlockExplorer: process.env.POLYGON_BLOCK_EXPLORER,
    ethereumJsonRpc: process.env.ETHEREUM_JSON_RPC,
    ethereumChainId: process.env.ETHEREUM_CHAIN_ID,
    ethereumBlockExplorer: process.env.ETHEREUM_BLOCK_EXPLORER,
    apiUrl: process.env.API_URL,
    walletConnectProjectId: process.env.WALLET_CONNECT_PROJECT_ID,
    privacyPolicyUrl:
        'https://cdn.divetalent.com/assets/legal/Dive-Privacy-Policy.pdf',
    termsOfUseUrl:
        'https://cdn.divetalent.com/assets/legal/Dive-Terms-Of-Use.pdf',
    polygonHubAddress: process.env.POLYGON_HUB_ADDRESS,
};

(Object.keys(_DiveConfiguration) as (keyof DiveConfigurationType)[]).forEach(
    (key) => {
        if (!_DiveConfiguration[key])
            throw new Error(`Invalid configuration value for ${key}`);
    }
);

export const DiveConfiguration = _DiveConfiguration as DiveConfigurationType;
